// src/App.jsx

import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoadingScreen from './components/LoadingScreen';

// Carga tus componentes de forma perezosa
const Auth = lazy(() => import('./components/auth'));
const Main = lazy(() => import('./components/main'));
const Analytics = lazy(() => import('./components/analytics'));
const PrivateRoute = lazy(() => import('./components/PrivateRoute'));
const CartillaAnimica = lazy(() => import('./components/CartillaAnimica'));
const WaitList = lazy(() => import('./components/WaitList'));
const Landing = lazy(() => import('./components/Landing'));
const Configuration = lazy(() => import('./components/Configuration'));
const Journal = lazy(() => import('./components/Journal'));
const CreatorDashboard = lazy(() => import('./components/creator/CreatorDashboard'));
const ProgramView = lazy(() => import('./components/programs/ProgramView'));

const App = () => {
  return (
    <Router>
      <Suspense 
        fallback={<LoadingScreen />}
        onTimeout={() => <div>La carga está tardando más de lo esperado...</div>}
        timeout={5000}
      >
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute fallback="/landing">
                <Suspense fallback={<LoadingScreen />}>
                  <Main />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route path="/landing" element={<Landing />} />
          <Route path="/result" element={<Analytics path="/result" />} />
          <Route path="/login" element={<Auth path="/login" />} />
          <Route path="/signup" element={<Auth path="/signup" />} />
          <Route path="/waitlist" element={<WaitList />} />
          <Route path="/cartilla" element={<CartillaAnimica />} />
          <Route path="/stats" element={<Analytics path="/stats" />} />
          <Route path="/config" element={<Configuration />} />
          <Route path="/journal" element={<Journal />} />
          <Route 
            path="/creadores" 
            element={
              <PrivateRoute creatorOnly={true}>
                <CreatorDashboard />
              </PrivateRoute>
            } 
          />
          <Route path="/:programId" element={<ProgramView />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
