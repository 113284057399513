// src/firebase/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, GoogleAuthProvider, signOut } from "firebase/auth";
import { getFirestore, enableIndexedDbPersistence } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";

// Asegúrate de que las variables de entorno estén definidas en tu archivo .env
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const firestore = getFirestore(app);

const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  prompt: "select_account",
  // Si necesitas establecer el client_id, asegúrate de que está correctamente configurado
  // client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
});

enableIndexedDbPersistence(db).catch((err) => {
  if (err.code === 'failed-precondition') {
    console.warn("Multiple tabs open, persistence can only be enabled in one tab at a time.");
  } else if (err.code === 'unimplemented') {
    console.warn("The current browser does not support all of the features required to enable persistence");
  }
});

const logout = () => signOut(auth);

// Configuración específica para Storage
const storageConfig = {
  maxOperationRetryTime: 10000,
  maxUploadRetryTime: 10000,
  customDomain: undefined // Asegúrate de que esto esté indefinido para usar el dominio por defecto
};

// Aplicar configuración al storage
Object.assign(storage, storageConfig);

export {
  auth,
  db,
  storage,
  firestore,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  googleProvider,
  logout,
};
